/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faNewspaper,
  faThLarge,
  faBriefcase,
  faBrain,
  faAddressCard,
  faEnvelope,
  faPhone,
  faPaperPlane,
  faUser,
  faCommentAlt,
  faPlayCircle,
  faInfoCircle,
  faLayerGroup,
  faSms,
  faTimesCircle,
  faCode,
  faChevronCircleLeft,
  faFilePdf,
  faImage,
  faFileAlt,
  faCheck,
  faQuestionCircle,
  faHome,
  faPodcast,
  faVideo,
  faCloudSunRain,
  faCalendarAlt,
  faBackward,
  faForward,
  faStepForward,
  faStepBackward,
  faFastBackward,
  faFastForward,
  faPlay,
  faStop,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretSquareDown,
  faClock,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faArrowCircleDown,
  faCheckCircle,
  faBan,
  faHourglass
  
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faNewspaper,
  faThLarge,
  faBriefcase,
  faBrain,
  faAddressCard,
  faEnvelope,
  faPhone,
  faPaperPlane,
  faUser,
  faCommentAlt,
  faPlayCircle,
  faInfoCircle,
  faLayerGroup,
  faSms,
  faTimesCircle,
  faCode,
  faChevronCircleLeft,
  faFilePdf,
  faImage,
  faFileAlt,
  faCheck,
  faQuestionCircle,
  faHome,
  faPodcast,
  faVideo,
  faCloudSunRain,
  faCalendarAlt,
  faBackward,
  faForward,
  faStepForward,
  faStepBackward,
  faFastBackward,
  faFastForward,
  faPlay,
  faStop,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretSquareDown,
  faClock,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faArrowCircleDown,
  faCheckCircle,
  faBan,
  faHourglass
)
