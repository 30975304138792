// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bio-js": () => import("/opt/build/repo/src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-calendar-js": () => import("/opt/build/repo/src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-clock-js": () => import("/opt/build/repo/src/pages/clock.js" /* webpackChunkName: "component---src-pages-clock-js" */),
  "component---src-pages-code-js": () => import("/opt/build/repo/src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-craft-js": () => import("/opt/build/repo/src/pages/craft.js" /* webpackChunkName: "component---src-pages-craft-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-docs-js": () => import("/opt/build/repo/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-giftastic-play-js": () => import("/opt/build/repo/src/pages/giftastic-play.js" /* webpackChunkName: "component---src-pages-giftastic-play-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-podcasts-js": () => import("/opt/build/repo/src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-wakatime-js": () => import("/opt/build/repo/src/pages/wakatime.js" /* webpackChunkName: "component---src-pages-wakatime-js" */),
  "component---src-pages-weather-js": () => import("/opt/build/repo/src/pages/weather.js" /* webpackChunkName: "component---src-pages-weather-js" */),
  "component---src-pages-youtube-js": () => import("/opt/build/repo/src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */)
}

